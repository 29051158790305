import React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { Address } from '../../types'
import {env} from '../../../environment'

export interface AddressProps {
  title: string
  address: Address
}

export default function AddressReview(props: AddressProps) {
  const address = props.address

  const addressLines = [address.first_name + ' ' + address.last_name]

  if (address.company) {
    addressLines.push(address.company)
  }

  addressLines.push(address.street + ' ' + address.housenumber)
  addressLines.push(address.zip + ' ' + address.city)

  if (env.getCountry() != address.country) {
    addressLines.push(address.country.toUpperCase())
  }

  return (
    <Grid item sm={6} xs={12}>
      <Typography gutterBottom variant="h6">
        {props.title}
      </Typography>
      {addressLines.map((addressLine: string, i) => (
        <Typography gutterBottom key={'address_line_' + i}>
          {addressLine}
        </Typography>
      ))}
    </Grid>
  )
}
