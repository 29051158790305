import React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import SelectButton from '../../components/generic/SelectButton'
import { getCart, setCartPayment } from '../../lib/cart_hook'
import CmsBlock from '../../components/cms/CmsBlock'
import { getValidPaymentWithOptions } from '../../utils/PaymentSelection'
import PaymentFormSepa from './PaymentFormSepa'
import { useTranslation } from 'react-i18next'
import {
  pushTmEvent,
  setEnvironmentVariable,
} from '../../components/layout/EnvironmentVariables'
import { getHash } from '../../utils/Crypto'

export default function PaymentFormInner(props: any) {
  const setValid = props.setValid
  const options = props.options
  const cart = getCart()

  const { t } = useTranslation('translation')

  const [selectedKey, setSelectedKey] = React.useState(
    getValidPaymentWithOptions(cart.payment, options),
  )

  if (selectedKey != cart.payment) {
    if (!setCartPayment(selectedKey)) {
      props.setErrorOpen()
    }
  }

  const handleSelect = (key: string) => {
    setSelectedKey(key)

    if (!setCartPayment(key)) {
      props.setErrorOpen()
    }
  }

  const signature = getHash(options)

  if (selectedKey !== 'sepa') {
    setValid(true)
  }

  let hasInvoice = false
  let hasSepa = false
  const hasDeliveryAddress = typeof cart.invoice_address !== 'undefined'

  options.map((paymentOption: any) => {
    if (paymentOption.key == 'invoice') {
      hasInvoice = true
    } else if (paymentOption.key == 'sepa') {
      hasSepa = true
    }
  })

  setEnvironmentVariable('has_invoice', hasInvoice ? 'yes' : 'no')
  setEnvironmentVariable('has_sepa', hasSepa ? 'yes' : 'no')
  setEnvironmentVariable(
    'has_delivery_address',
    hasDeliveryAddress ? 'yes' : 'no',
  )
  pushTmEvent('artboxone-paymentform')

  return (
    <React.Fragment>
      <Typography gutterBottom variant="h6">
        {t('Zahlungsart')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <SelectButton
            handleSelect={handleSelect}
            key={signature}
            name={'checkout_select_payment'}
            options={options}
            replace={true}
            selectedKey={selectedKey}
          />
        </Grid>
        {selectedKey == 'sepa' && (
          <PaymentFormSepa
            setErrorOpen={props.setErrorOpen}
            setValid={props.setValid}
          />
        )}
        <Grid item md={12} xs={12}>
          <CmsBlock blockId={'payment_' + selectedKey} />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
