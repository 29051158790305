import { SelectButtonOption, CartItem } from '../types'
import { setUuid } from '../lib/cart_hook'
import { usePaymentData } from '../lib/payment/payment_hooks'
import { PaymentProductData } from '../lib/payment/payment_api'
import { isMobile, isTablet } from 'react-device-detect'

export const isEuroCountry = (country: string) => {
  return ['de', 'at', 'be', 'nl', 'fr'].indexOf(country) !== -1
}

export const getPaymentOptions = (
  country: string,
  hasInvoiceAddress: boolean,
  cartItems?: CartItem[],
  voucherCode?: string,
  mail?: string,
) => {
  const options: SelectButtonOption[] = []

  const products: PaymentProductData[] = []

  cartItems?.map((cartItem: CartItem) => {
    if (typeof cartItem.materialIId === 'number') {
      products.push({
        count: cartItem.count,
        materialIId: cartItem.materialIId,
      })
    } else {
      products.push({ count: cartItem.count, productId: cartItem.materialId })
    }
  })

  const furtherOptions = usePaymentData({
    products: products,
    voucherCode: voucherCode,
    country: country,
    deviceType: isTablet ? 't' : isMobile ? 'm' : 'd',
    hasInvoiceAddress: hasInvoiceAddress,
    mail: mail,
  })

  if (furtherOptions !== undefined) {
    if (process.env.SHOW_CMS_LINKS == '1') {
      options.push({
        key: 'testok',
        name: 'TESTING Auftrag OK',
      })
      options.push({
        key: 'testfail',
        name: 'TESTING Auftrag Fehler',
      })
    }

    let uuid: string | undefined = undefined
    furtherOptions.map((paymentOption: any) => {
      if (uuid === undefined) {
        uuid = paymentOption.uuid
      }

      options.push(paymentOption)
    })

    if (uuid !== undefined) {
      setUuid(uuid)
    }
  } else {
    // if (isEuroCountry(country) || ['ch', 'gb'].indexOf(country) !== -1) {
    //   options.push({ key: 'paypal', name: 'Paypal' })
    // }
    // if (['dk'].indexOf(country) !== -1) {
    //   options.push({ key: 'dankort', name: 'Dankort' })
    // }
    // options.push({ key: 'visa', name: 'Visa' })
    // options.push({ key: 'mastercard', name: 'Mastercard' })
  }

  return options
}

export const getPaymentName = (payment: string) => {
  // TODO
  const result = payment.toUpperCase()

  return result
}

// Fallback to a valid payment if given payment is invalid
export const getValidPayment = (
  payment: string,
  country: string,
  hasInvoiceAddress: boolean,
) => {
  const validPayments: string[] = []
  const options = getPaymentOptions(country, hasInvoiceAddress)
  options.map((option: SelectButtonOption) => {
    validPayments.push(option.key)
  })

  if (validPayments.length == 0) {
    return undefined
  }

  if (!country || validPayments.indexOf(payment) === -1) {
    payment = options[0].key
  }

  return payment
}

export const getValidPaymentWithOptions = (payment: string, options: any[]) => {
  const validPayments: string[] = []
  options.map((option: SelectButtonOption) => {
    validPayments.push(option.key)
  })

  if (validPayments.indexOf(payment) === -1) {
    payment = options[0].key
  }

  return payment
}

// export const getCartPayment = (country: string) => {
//   let cart = getCart()

//   let result = undefined
//   if (typeof cart.payment !== 'undefined') {
//     result = cart.payment
//   }

//   result = getValidPayment(result, country)
//   return result
// }
