import React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import CmsBlock from '../cms/CmsBlock'
import { useTranslation } from 'react-i18next'

export interface PaymentProps {
  payment: string
}

export default function PaymentReview(props: PaymentProps) {
  const payment = props.payment

  const { t } = useTranslation('translation')
  return (
    <Grid item sm={6} xs={12}>
      <Typography gutterBottom variant="h6">
        {t('Zahlung')}
      </Typography>
      <CmsBlock blockId={'payment_short_' + payment} />
    </Grid>
  )
}
