import React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { getCartPaymentData, setCartPaymentData } from '../../lib/cart_hook'

interface PaymentFormProps {
  setValid: (arg0: boolean) => unknown
  setErrorOpen: (arg0: boolean) => unknown
}

interface PaymentData {
  paymentName: string
  paymentNumber: string
  [key: string]: string
}

interface PaymentErrors {
  paymentName?: boolean
  paymentNumber?: boolean
}

export const validate = (
  paymentName: string,
  paymentNumber: string,
): PaymentErrors => {
  const errors: PaymentErrors = {}

  if (paymentName.length < 2) {
    errors['paymentName'] = true
  }

  if (paymentNumber.substr(0, 2) != 'DE') {
    errors['paymentNumber'] = true
  }

  if (paymentNumber.length != 22) {
    errors['paymentNumber'] = true
  }

  if (
    !/^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/.test(
      paymentNumber,
    )
  ) {
    errors['paymentNumber'] = true
  }

  return errors
}

export const cleanupPaymentNumber = (paymentNumber: string) => {
  const result = paymentNumber.match(new RegExp('.{1,4}', 'g'))
  return result !== null ? result.join(' ') : ''
}

export default function PaymentFormSepa(props: PaymentFormProps) {
  const [paymentData, setPaymentData] = React.useState(getCartPaymentData())
  let visiblePaymentNumber = cleanupPaymentNumber(paymentData.paymentNumber)
  const errors: PaymentErrors = validate(
    paymentData.paymentName,
    paymentData.paymentNumber,
  )

  const errorKeys = Object.keys(errors)
  props.setValid(errorKeys.length == 0)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tf = event.target as HTMLInputElement

    const newData: PaymentData = {
      paymentName: paymentData.paymentName,
      paymentNumber: paymentData.paymentNumber,
    }

    let value = tf.value.toUpperCase()

    if (tf.name == 'paymentNumber') {
      if (value.length > 0) {
        value = value.split(' ').join('').trim()
      }
    }

    newData[tf.name] = value

    setPaymentData(newData)

    if (setCartPaymentData(newData.paymentName, newData.paymentNumber)) {
      visiblePaymentNumber = cleanupPaymentNumber(newData.paymentNumber)
    } else {
      props.setErrorOpen(true)
    }
  }

  return (
    <>
      <Grid item md={6} xs={12}>
        <TextField
          error={errorKeys.indexOf('paymentName') >= 0}
          fullWidth
          label="Kontoinhaber"
          name="paymentName"
          onChange={handleChange}
          required
          value={paymentData.paymentName}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          error={errorKeys.indexOf('paymentNumber') >= 0}
          fullWidth
          label="IBAN (DE...)"
          name="paymentNumber"
          onChange={handleChange}
          required
          value={visiblePaymentNumber}
        />
      </Grid>
    </>
  )
}
