import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { Address, SelectButtonOption } from '../../types'
import SelectButton from '../../components/generic/SelectButton'
import TwoActionsDialog from '../../components/generic/TwoActionsDialog'
import { navigate } from 'gatsby'
import {
  getCountryOptions,
  getValidCountry,
} from '../../utils/CountrySelection'
import { trackEvent } from '../../utils/ArtboxoneTracking'
import { useTranslation } from 'react-i18next'
import { Alert } from 'react-bootstrap'

interface ValidationError {
  visible?: boolean
  message: string;
}

type Errors = { [key: string]: ValidationError }

export const getDeliveryOptionKey = (address: Address) => {
  if (address.street == 'Packstation' && address.country == 'de') {
    return 'packstation'
  } else if (typeof address.company !== 'undefined' && address.company != '') {
    return 'company'
  } else {
    return 'private'
  }
}

export const validate = (
  address: Address,
  changed: string | undefined,
  deliveryOption?: string,
) => {
  let isPackstation = false
  let isCompany = false

  if (deliveryOption == 'packstation') {
    isPackstation = true
  } else if (deliveryOption == 'company') {
    isCompany = true
  }

  const errors: Errors = {}

  let keys = [
    'first_name',
    'last_name',
    'street',
    'housenumber',
    'zip',
    'city',
    'country',
  ]

  if (changed !== undefined) {
    keys = [changed]
  }

  keys.map((key) => {
    if (
      typeof address[key] === 'undefined' ||
      address[key].trim().length < 1 ||
      /^[ _\-]+$/.test(address[key].trim())
    ) {
      errors[key] = { visible: false, message: 'Eingabe ungültig' }
    }
  })

  let regex = /^[0-9]+$/
  let message = 'Bitte nur Zahlen bei der Postleitzahl eintragen!'

  switch (address.country) {
    case 'de':
      regex = /^[0-9]{5}$/
      message = 'Postleitzahl bitte mit fünf Stellen angeben.'
      break
    case 'dk':
    case 'be':
      regex = /^[0-9]{4}$/
      message = 'Invalid zipcode.'
      break
    case 'es':
      regex = /^[0-9]{5}$/
      message = 'Invalid zipcode.'
      break
    case 'ch':
    case 'at':
      regex = /^[0-9]{4}$/
      message = 'Postleitzahl bitte mit vier Stellen angeben.'
      break
    case 'nl':
      regex = /^[0-9]{4} ?[a-z]{2}$/i
      message = 'Postleitzahl ungültig.'
      break
    case 'gb':
      regex = /^[a-z]{1,2}[0-9r][0-9a-z]? ?[0-9]{1,2}[abd-hjlnp-uw-z]{2}$/i
      message = 'Invalid zipcode.'
      break
    default:
      break
  }

  if (changed === undefined || changed == 'zip') {
    if (!regex.test(address.zip)) {
      if (typeof address.zip !== 'undefined' && address.zip != '') {
        errors['zip'] = { visible: true, message: message }
      } else {
        errors['zip'] = { visible: false, message: message }
      }
    }
  }

  if (isPackstation || isCompany) {
    if (
      typeof address.company === 'undefined' ||
      address.company.trim() == '' ||
      /^[ _\-]+$/.test(address.company.trim())
    ) {
      errors['company'] = {
        visible: false,
        message: 'Bitte ausfüllen',
      }
    }
  }

  if (!isPackstation) {
    if (
      typeof address.street !== 'undefined' &&
      /^[0-9]+$/.test(address.street.trim())
    ) {
      errors['street'] = {
        visible: true,
        message: 'Straßenname bitte nicht nur aus Zahlen',
      }
    }
  }

  if (
    typeof address.street !== 'undefined' &&
    typeof address.housenumber !== 'undefined'
  ) {
    let test = address.street + ' ' + address.housenumber
    test = test.trim()

    if (test.length > 38) {
      errors['street'] = {
        visible: true,
        message:
          'Straße und Hausnummer zusammen dürfen nicht länger als 38 Zeichen sein',
      }
    }
  }

  if (isPackstation) {
    if (
      typeof address.company !== 'undefined' &&
      !/^[0-9]+$/.test(address.company)
    ) {
      errors['company'] = {
        visible: true,
        message: 'Die Postnummer darf nur Zahlen enthalten',
      }
    }

    if (
      typeof address.housenumber !== 'undefined' &&
      !/^[0-9]+$/.test(address.housenumber)
    ) {
      errors['housenumber'] = {
        visible: true,
        message: 'Die Packstationsnummer darf nur Zahlen enthalten',
      }
    }
  } else {
    if (
      /^[0-9]+$/.test(address.company) &&
      typeof address.street !== 'undefined' &&
      address.street.toLowerCase().indexOf('packstation') >= 0
    ) {
      errors['company'] = {
        visible: true,
        message:
          'Es scheint, als ob du ein Paket an eine Packstation senden möchtest. Dies klappt nur innerhalb Deutschlands mit "Packstation" in der Auswahlbox oben.',
      }
    }

    if (typeof address.street !== 'undefined') {
      if (
        address.street.toLowerCase().indexOf('poststation') >= 0 ||
        address.street.toLowerCase().indexOf('packstation') >= 0 ||
        address.street.toLowerCase().indexOf('pack stat') >= 0
      ) {
        errors['street'] = {
          visible: true,
          message:
            'Es scheint, als ob du ein Paket an eine Packstation senden möchtest. Dies klappt nur innerhalb Deutschlands mit "Packstation" in der Auswahlbox oben.',
        }
      }
    }
  }

  let logStreetError = false

  if (
    typeof address.street !== 'undefined' &&
    typeof address.housenumber !== 'undefined'
  ) {
    const regex = /^(.+)\s+([0-9][0-9a-zA-Z-]*)$/
    const found = address.street.match(regex)

    if (found && address.housenumber.length > 7 && address.street.length > 5) {
      errors['street'] = {
        visible: true,
        message:
          'Es scheint, als ob du die Hausnummer im Straßenfeld angegeben hast und irgendetwas anderes in dem Hausnummern-Feld. Bitte prüfen und möglichst das Straßenfeld nicht mit Nummern enden lassen.',
      }

      logStreetError = true
    } else if (found && address.housenumber == found[2]) {
      errors['street'] = {
        visible: true,
        message:
          'Es scheint, als ob du die Hausnummer im Straßenfeld und zusätzlich im Hausnummern-Feld angegeben hast. Bitte die Hausnummer nicht im Straßenfeld angeben.',
      }

      logStreetError = true
    }
  }

  return errors
}

export default function InnerAddressForm(props: any) {
  let address = props.address
  const [openDialog, setOpenDialog] = React.useState(false)
  const [newCountry, setNewCountry] = React.useState('')
  const [deliveryOptionSelectedKey, setDeliveryOptionSelectedKey] =
    React.useState(getDeliveryOptionKey(address))

  const { t } = useTranslation('translation')

  let isPackstation = false

  if (deliveryOptionSelectedKey == 'packstation') {
    isPackstation = true
  }

  const errors = validate(address, undefined, deliveryOptionSelectedKey)
  const errorKeys = Object.keys(errors)
  props.setValid(errorKeys.length == 0)

  const visibleErrors = Object.values(errors).filter((error: ValidationError) => {
    return error.visible == true
  })

  const dialog = (
    <>
      {t(
        'Durch die Änderung des Lieferlandes ergeben sich ggf. neue Preise (Versandkosten, Währung etc). Bitte prüfe die neuen Preise!',
      )}
    </>
  )

  const handleShowPrices = () => {
    const changedElement = {
      country: newCountry,
    }

    address = { ...address, ...changedElement }
    props.setAddress(address)

    if (!props.storeAddress(address)) {
      props.setErrorOpen(true)
    } else {
      setOpenDialog(false)
      navigate('/warenkorb')
    }
  }

  const handleAbort = () => {
    setOpenDialog(false)
  }

  const handleClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    let invoiceAddressActive = event.target.checked

    if (deliveryOptionSelectedKey == 'packstation') {
      invoiceAddressActive = true
    }

    if (!invoiceAddressActive) {
      props.setAdditionalAddress({})
    }

    props.setHasAdditionalAddress(invoiceAddressActive)

    trackEvent({
      category: 'checkout',
      action: 'invoice-address',
      label: event.target.checked ? 'yes' : 'no',
    })
  }

  const handleSelectDeliveryOption = (key: string) => {
    setDeliveryOptionSelectedKey(key)

    if (key == 'private') {
      const changedElement: Partial<Address> = {}
      changedElement['company'] = ''
      address = { ...address, ...changedElement }
      props.setAddress(address)
      props.setValid(validate(address, 'company'))

      if (!props.storeAddress(address)) {
        props.setErrorOpen(true)
      }
    } else if (key == 'packstation') {
      const changedElement: Partial<Address> = {}
      changedElement['country'] = 'de'
      changedElement['street'] = 'Packstation'
      address = { ...address, ...changedElement }
      props.setAddress(address)
      props.setValid(validate(address, 'country'))

      if (!props.storeAddress(address)) {
        props.setErrorOpen(true)
      }

      props.setHasAdditionalAddress(true)
    }
  }

  const handleSelect = (key: string) => {
    setNewCountry(key)

    // Changed delivery-address -> Show price modal
    if (
      address.country != key &&
      props.showAdditionalAddressCheckbox === true
    ) {
      setOpenDialog(true)
    } else {
      // Change invoice address
      const changedElement: Partial<Address> = {
        country: key,
      }

      address = { ...address, ...changedElement }
      props.setAddress(address)

      if (!props.storeAddress(address)) {
        props.setErrorOpen(true)
      }
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tf = event.target as HTMLInputElement;
    const changedElement: Partial<Address> = {}

    changedElement[tf.name as keyof Address] = tf.value
    address = { ...address, ...changedElement }
    props.setAddress(address)
    props.setValid(validate(address, tf.name))

    if (!props.storeAddress(address)) {
      props.setErrorOpen(true)
    }
  }

  let options = getCountryOptions(props.constraintCountry)
  const validCountry = getValidCountry(address.country, props.constraintCountry)

  if (validCountry !== address.country) {
    address.country = validCountry
    props.setAddress(address)

    if (!props.storeAddress(address)) {
      props.setErrorOpen(true)
    }
  }

  const allowPackstation =
    address.country == 'de' && props.showAdditionalAddressCheckbox === true

  const deliveryOptions: SelectButtonOption[] = []

  if (props.showAdditionalAddressCheckbox === true) {
    deliveryOptions.push({ key: 'private', name: 'Privat' })
    deliveryOptions.push({ key: 'company', name: 'Firma' })

    if (allowPackstation) {
      deliveryOptions.push({ key: 'packstation', name: 'Packstation' })
    }
  }

  const showCompanyField =
    deliveryOptionSelectedKey == 'company' ||
    props.showAdditionalAddressCheckbox === false

  let showInvoiceAddressCheckbox = props.showAdditionalAddressCheckbox

  if (deliveryOptionSelectedKey == 'packstation') {
    options = []
    showInvoiceAddressCheckbox = false
    options.push({ key: 'de', name: 'Deutschland' })
  }

  return (
    <React.Fragment>
      <Typography gutterBottom variant="h6">
        {props.title}
      </Typography>
      <Grid container spacing={3}>
        {Object.keys(errors).length > 0 && (
          <Grid item sm={12} xs={12}>
            {props.showAdditionalAddressCheckbox === false && (
              <Alert key="invoice_alerting" variant="danger">
                <ul>
                  {visibleErrors.map((error: ValidationError, index: number) => (
                    <li key={index}>{t(error.message)}</li>
                  ))}
                </ul>
              </Alert>
            )}
            {props.description}
          </Grid>
        )}

        {deliveryOptions.length > 0 && (
          <Grid item xs={12}>
            <SelectButton
              handleSelect={handleSelectDeliveryOption}
              name={'select_delivery_option'}
              options={deliveryOptions}
              replace={true}
              selectedKey={deliveryOptionSelectedKey}
            />
          </Grid>
        )}

        <Grid item sm={6} xs={12}>
          <TextField
            autoComplete="given-name"
            error={errorKeys.indexOf('first_name') >= 0}
            fullWidth
            label={t('Vorname')}
            name="first_name"
            onChange={handleChange}
            required
            value={address.first_name}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            autoComplete="family-name"
            error={errorKeys.indexOf('last_name') >= 0}
            fullWidth
            label={t('Nachname')}
            name="last_name"
            onChange={handleChange}
            required
            value={address.last_name}
          />
        </Grid>
        {isPackstation === false && showCompanyField === true && (
          <Grid item xs={12}>
            <TextField
              autoComplete="billing organization"
              error={errorKeys.indexOf('company') >= 0}
              fullWidth
              helperText={
                errorKeys.indexOf('company') >= 0 && (
                  <span>
                    {errors['company'].visible === true &&
                      errors['company'].message}
                  </span>
                )
              }
              label={t('Firma')}
              name="company"
              onChange={handleChange}
              value={address.company}
            />
          </Grid>
        )}
        {isPackstation === false && (
          <Grid item xs={9}>
            <TextField
              autoComplete="billing street-address"
              error={errorKeys.indexOf('street') >= 0}
              fullWidth
              helperText={
                errorKeys.indexOf('street') >= 0 && (
                  <span>
                    {errors['street'].visible === true &&
                      errors['street'].message}
                  </span>
                )
              }
              label={t('Straße')}
              name="street"
              onChange={handleChange}
              required
              value={address.street}
            />
          </Grid>
        )}
        {isPackstation === false && (
          <Grid item xs={3}>
            <TextField
              autoComplete="billing"
              error={errorKeys.indexOf('housenumber') >= 0}
              fullWidth
              label={t('Nummer')}
              name="housenumber"
              onChange={handleChange}
              required
              value={address.housenumber}
            />
          </Grid>
        )}

        {isPackstation === true && (
          <Grid item xs={12}>
            <TextField
              autoComplete="billing organization"
              error={errorKeys.indexOf('company') >= 0}
              fullWidth
              helperText={
                errorKeys.indexOf('company') >= 0 && (
                  <span>
                    {errors['company'].visible === true &&
                      errors['company'].message}
                  </span>
                )
              }
              label="Postnummer"
              name="company"
              onChange={handleChange}
              value={address.company}
            />
          </Grid>
        )}
        {isPackstation === true && (
          <Grid item xs={9}>
            <TextField
              autoComplete="billing street-address"
              fullWidth
              helperText={
                errorKeys.indexOf('street') >= 0 && (
                  <span>
                    {errors['street'].visible === true &&
                      errors['street'].message}
                  </span>
                )
              }
              label="Packstation XXX"
              name="street"
              required
              value={'Packstation'}
            />
          </Grid>
        )}
        {isPackstation === true && (
          <Grid item xs={3}>
            <TextField
              autoComplete="billing"
              error={errorKeys.indexOf('housenumber') >= 0}
              fullWidth
              helperText={
                errorKeys.indexOf('housenumber') >= 0 && (
                  <span>
                    {errors['housenumber'].visible === true &&
                      errors['housenumber'].message}
                  </span>
                )
              }
              label={'Nummer'}
              name="housenumber"
              onChange={handleChange}
              required
              value={address.housenumber}
            />
          </Grid>
        )}

        <Grid item sm={6} xs={12}>
          <TextField
            autoComplete="billing postal-code"
            error={errorKeys.indexOf('zip') >= 0}
            fullWidth
            helperText={
              errorKeys.indexOf('zip') >= 0 && (
                <span>
                  {errors['zip'].visible === true && errors['zip'].message}
                </span>
              )
            }
            label={t('Postleitzahl')}
            name="zip"
            onChange={handleChange}
            required
            value={address.zip}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            autoComplete="billing address-level2"
            error={errorKeys.indexOf('city') >= 0}
            fullWidth
            label={t('Stadt')}
            name="city"
            onChange={handleChange}
            required
            value={address.city}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SelectButton
            handleSelect={handleSelect}
            name={'select_country'}
            options={options}
            replace={true}
            selectedKey={address.country}
          />
        </Grid>
        {showInvoiceAddressCheckbox === true && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.hasAdditionalAddress}
                  color="primary"
                  onClick={handleClick}
                  value="yes"
                />
              }
              label={t('Abweichende Rechnungsanschrift')}
            />
          </Grid>
        )}
        {showInvoiceAddressCheckbox === false && isPackstation === true && (
          <Grid item xs={12}>
            <Alert
              key="invoice_alerting"
              style={{ textAlign: `center` }}
              variant="primary"
            >
              Bei Lieferung an eine Packstation muss eine Rechnungsanschrift
              hinterlegt werden. Rechnungen an Packstationen mag DHL nicht.
            </Alert>
          </Grid>
        )}

        <Grid item sm={12} xs={12}>
          {props.showAdditionalAddressCheckbox === false && (
            <Alert
              key="invoice_alerting"
              style={{ textAlign: `center` }}
              variant="primary"
            >
              Mit einer abweichenden Rechnungsanschrift werden nicht alle
              Zahlungsarten angeboten.
            </Alert>
          )}
          {props.description}
        </Grid>
      </Grid>
      <TwoActionsDialog
        dialog={dialog}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        negative={t('Abbrechen')}
        negativeAction={handleAbort}
        open={openDialog}
        positive={t('Preise anzeigen')}
        positiveAction={handleShowPrices}
        setOpen={setOpenDialog}
        title={t('Neue Preise')}
      />
    </React.Fragment>
  )
}
